


import * as common from "./api/common"
import * as admin from "./api/admin"


const Api = {
    common, admin
}
export default Api;

// var formData = new FormData();
// for(var key in params){
//     formData.append(key,params[key]);
// }